import { Alert, AlertDescription, AlertIcon, Container, Flex, Link } from '@chakra-ui/react'
import Trans from 'next-translate/Trans'
import { FunctionComponent } from 'react'
import React from 'react'

import Footer from 'components/modules/Footer'
import LoginHeader from 'components/modules/Login/LoginHeader'

const BG_IMAGE_URL = '/images/login-bg.jpg'

interface LoginLayoutProps {
  children: NonNullable<React.ReactNode>
  displayLoginPageAlert?: boolean
}

const styles = {
  container: { display: 'flex', flexDirection: 'column', maxW: 'full', h: '100vh', p: 0 },
  wrapper: {
    flexDirection: 'column',
    maxW: 'full',
    h: 'full',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: { lg: `url(${BG_IMAGE_URL})` },
    boxShadow: { lg: 'inset 0 0 0 100vw rgba(0,0,0,0.6)' },
    backgroundSize: { lg: 'cover' },
    backgroundPosition: { lg: 'center' },
  },
  modal: {
    maxW: '428px',
    direction: 'column',
    bgColor: 'primary.white',
    padding: { base: 6, md: 10 },
    zIndex: 'modal',
    my: 6,
  },
  alert: {
    minH: 16,
    w: { base: 'calc(100vw - 48px)', lg: 'full' },
    justifyContent: 'center',
    alignSelf: 'center',
    px: 0,
    py: 6,
  },
  alertWrapper: { maxW: 'desktop', px: { base: 6, lg: 10 }, w: 'full', align: 'center' },
} as const

const LoginLayout: FunctionComponent<LoginLayoutProps> = ({ children, displayLoginPageAlert = false }) => {
  return (
    <Container id="LoginLayout" {...styles.container}>
      <LoginHeader />
      {displayLoginPageAlert && (
        <Alert status="info" variant="warning" {...styles.alert}>
          <Flex {...styles.alertWrapper}>
            <AlertIcon />
            <AlertDescription>
              <Trans
                ns="login"
                i18nKey="alertMessage"
                components={{
                  Link: <Link href="https://www.normet.com/contact/" target="_blank" rel="noreferrer noopener" />,
                }}
              />
            </AlertDescription>
          </Flex>
        </Alert>
      )}
      <Flex {...styles.wrapper}>
        <Flex {...styles.modal}>{children}</Flex>
      </Flex>
      <Footer bgColor="primary.white" hideContactLink />
    </Container>
  )
}

export default LoginLayout
