import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useEffect } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import SectionLoading from 'components/elements/SectionLoading'
import LoginLayout from 'components/layouts/LoginLayout'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import NoAccessError from 'components/modules/Auth/NoAccessError'
import { tokenRequest } from 'config/auth'
import { RoutePaths } from 'config/routes'
import { hasCustomerAccessToStore } from 'utils/hasAccessToStore'

interface GuardAuthorizedProps {
  children: ReactNode
}

const TAG = 'GuardAuthorized'

const GuardAuthorized: FunctionComponent<GuardAuthorizedProps> = ({ children }) => {
  const { instance, inProgress } = useMsal()
  const { status } = useAuthState()
  const { isReady, push, route } = useRouter()
  const { all, status: buStatus } = useBusinessUnit()
  const hasAccessToStore = buStatus === 'success' && hasCustomerAccessToStore(all)

  useEffect(() => {
    // When the user is not authenticated, we want to redirect them to the login page if:
    // 1. MSAL is not in progress
    const noInteractionInProgress = inProgress === InteractionStatus.None
    // 2. User is not authenticated
    const isUnauthenticated = status === AuthStatus.Unauthenticated
    // 3. Router is ready and user is not on the login page
    const notOnLoginPage = isReady && route !== RoutePaths.login

    const attemptSilentLogin = async () => {
      if (noInteractionInProgress && isUnauthenticated && notOnLoginPage) {
        await instance.ssoSilent(tokenRequest).catch((res) => {
          if (!res.account) {
            push(RoutePaths.login)
          }
        })
      }
    }
    attemptSilentLogin()
  }, [inProgress, instance, isReady, push, route, status])

  if (
    buStatus === 'loading' ||
    buStatus === 'idle' ||
    status === AuthStatus.Loading ||
    status === AuthStatus.Unauthenticated ||
    inProgress !== InteractionStatus.None
  ) {
    return <SectionLoading />
  }

  if (status === AuthStatus.Authenticated) {
    if (!hasAccessToStore) {
      return (
        <LoginLayout>
          <NoAccessError />
        </LoginLayout>
      )
    }
  }
  return <>{children}</>
}

GuardAuthorized.displayName = TAG

export default GuardAuthorized
