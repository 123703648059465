import { Alert, AlertDescription, AlertIcon, Box, Container, Flex } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { CheckoutDisabledAlert } from 'components/elements/CheckoutDisabledAlert'
import Footer from 'components/modules/Footer'
import Header from 'components/modules/Header'
import CompanyAccess from 'components/modules/Security/CompanyAccess'
import SEO from 'components/modules/SEO'
import { Subheader } from 'components/modules/Subheader'

type Props = {
  children: NonNullable<React.ReactNode>
  title?: string | undefined
  subtitle?: string | undefined
  customSubHeader?: ReactNode
  infoText?: string
  isCheckout?: boolean
}

export function Layout({ children, title, subtitle, customSubHeader, infoText, isCheckout }: Props) {
  const isCheckoutDisabled = process.env.NEXT_PUBLIC_CHECKOUT_DISABLED === 'true'
  const isToastEnabled = !isCheckoutDisabled && process.env.NEXT_PUBLIC_TOAST_ENABLED === 'true'

  return (
    <Container minH="100vh" maxW="100vw" p={0}>
      <SEO title={title} />
      <Flex direction="column" minH="100vh">
        <Header isCheckout={isCheckout} />

        {isCheckoutDisabled && <CheckoutDisabledAlert justifyContent="center" px={4} align="center" />}

        {isToastEnabled && (
          <Alert status="info" variant="warning" minH={16} w="full" justifyContent="center">
            <Flex maxW="desktop" w="full" align="center" px={4}>
              <AlertIcon />
              <AlertDescription>{process.env.NEXT_PUBLIC_TOAST_MESSAGE}</AlertDescription>
            </Flex>
          </Alert>
        )}

        {(title || subtitle) && <Subheader infoText={infoText} title={title} subtitle={subtitle} />}
        {!(title && subtitle) && <Box id="CustomSubheader">{customSubHeader}</Box>}
        <Container flexGrow={1} p={0}>
          <Flex justifyContent="center" width="full" as="main">
            <CompanyAccess>{children}</CompanyAccess>
          </Flex>
        </Container>
        <Footer />
      </Flex>
    </Container>
  )
}
