
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Button, Container, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Select, Text, Textarea, } from '@chakra-ui/react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit';
import { useCustomer } from 'commercetools/hooks/use-customer';
import { useProductSearch } from 'commercetools/hooks/use-product-search';
import { getAttributeValue } from 'commercetools/utils/product/getAttributeValue';
import { getProductName } from 'commercetools/utils/product/getProductName';
import { getProductVariant } from 'commercetools/utils/product/getProductVariant';
import { getProductVariantDescription } from 'commercetools/utils/product/getProductVariantDescription';
import { SuccessAlert } from 'components/elements/SuccessAlert';
import { Layout } from 'components/layouts/Layout';
import { events } from 'gtm/events';
import { gtagEvent } from 'gtm/gtag';
import { useToastAlert } from 'hooks/useToastAlert';
import getCompanyName from 'utils/getCompanyName';
export const ITEMS_MISSING_PRICE_LIMIT = 25;
export enum ContactFormSubject {
    CustomerInformation,
    RequestPrice,
    Message,
    Order,
    Other
}
type ContactForm = {
    subject: string;
    message: string;
};
const ContactFormPage: NextPage = () => {
    const { t, lang } = useTranslation('contact-form');
    const { query } = useRouter();
    const toast = useToastAlert();
    const [customer] = useCustomer();
    const businessUnit = useBusinessUnit();
    const [subject, setSubject] = useState(ContactFormSubject.CustomerInformation);
    const [SKUs, setSKUs] = useState<string[]>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    //Needs to be outside useMemo/useEffect because t is changed on every render
    const customerInformationSubject = t('customer-information');
    const requestPriceSubject = t('request-price');
    const messageSubject = t('message');
    const orderSubject = t('order');
    const otherSubject = t('other');
    const subjectOptionsMap = useMemo(() => new Map<ContactFormSubject, string>([
        [ContactFormSubject.CustomerInformation, customerInformationSubject],
        [ContactFormSubject.RequestPrice, requestPriceSubject],
        [ContactFormSubject.Message, messageSubject],
        [ContactFormSubject.Order, orderSubject],
        [ContactFormSubject.Other, otherSubject],
    ]), [customerInformationSubject, messageSubject, orderSubject, otherSubject, requestPriceSubject]);
    const { handleSubmit, register, setValue, formState: { errors, isValid, isSubmitting }, } = useForm<ContactForm>({
        mode: 'onChange',
    });
    const { data: productsResponse, isLoading } = useProductSearch({
        limit: SKUs?.length || ITEMS_MISSING_PRICE_LIMIT,
        filter: [`variants.attributes.erpLegalEntity.key:${businessUnit.erpLegalEntity}`, `variants.sku:"${SKUs}"`],
        enabled: query?.skus !== undefined,
    });
    const priceMessageTranslation = `${t('request-price-message')}\n`;
    const populateRequestPriceMessage = useCallback(() => {
        if (query?.skus && !isLoading) {
            let message = priceMessageTranslation;
            productsResponse?.results?.forEach((product) => {
                const productVariant = getProductVariant(product);
                const productName = getProductName(product, lang);
                message = message.concat(`${getAttributeValue(productVariant?.attributes, 'itemNumber')} ${productName} ${getProductVariantDescription(productName, productVariant, lang) || ''}\n`);
            });
            setValue('message', message, { shouldValidate: true });
        }
    }, [isLoading, lang, priceMessageTranslation, productsResponse?.results, query?.skus, setValue]);
    const onSubmit = async (form: ContactForm) => {
        const data = {
            subject: form.subject,
            message: form.message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            language: lang,
            customerEmail: customer?.data?.email,
            fullName: `${customer?.data?.firstName} ${customer?.data?.lastName}`,
            company: getCompanyName(businessUnit.current),
        };
        await fetch('/api/email/sendContactFormEmail', {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(({ status }) => {
            if (status !== 200) {
                toast({ title: t('common:something-goes-wrong'), status: 'error' });
            }
            else {
                setIsSubmitted(true);
                if ((subject as ContactFormSubject) === ContactFormSubject.RequestPrice) {
                    gtagEvent({ event: events.requestPrice });
                }
            }
        });
    };
    const enableOrdersTranslation = t('enable-orders');
    useEffect(() => {
        if (query?.enableOrders && !isLoading) {
            setValue('message', enableOrdersTranslation, { shouldValidate: true });
        }
    }, [query, productsResponse, isLoading, setValue, enableOrdersTranslation]);
    useEffect(() => {
        if (query?.subject) {
            setSubject(Number(query?.subject));
        }
        if (query?.skus) {
            setSKUs(Array.isArray(query.skus) ? query.skus : [query.skus]);
        }
    }, [query]);
    useEffect(() => {
        if ((subject as ContactFormSubject) === ContactFormSubject.Other) {
            setValue('subject', '');
        }
        else {
            setValue('subject', subjectOptionsMap.get(subject as ContactFormSubject) || '');
        }
    }, [setValue, subject, subjectOptionsMap]);
    useEffect(() => {
        if (query?.skus && !isLoading) {
            populateRequestPriceMessage();
        }
    }, [query, productsResponse, isLoading, populateRequestPriceMessage]);
    return (<Layout>
      <Flex align="center" flexDir="column" w="788px">
        {isSubmitted && (<SuccessAlert mt={{ md: 9 }} mb={{ md: -4 }} borderRadius={{ md: '2px' }}>
            {t('common:success')}
          </SuccessAlert>)}
        <Container w="full" m={{ md: 10 }} px={{ base: 10, md: 20 }} py={{ base: 6, md: 10 }} background="primary.white" borderRadius={{ md: '2px' }}>
          <Heading as="h1">{isSubmitted ? t('thank-you-for-contacting-us') : t('common:contact-us')}</Heading>
          <Text my={6}>{isSubmitted ? t('contact-us-form-submitted-desc') : t('contact-us-form-desc')}</Text>
          {!isSubmitted && (<form style={{ display: 'flex', flexDirection: 'column', width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              <Select mb={8} value={subject} onChange={({ target: { value } }) => setSubject(Number(value))}>
                {Array.from(subjectOptionsMap).map(([key, value]) => (<option key={key} value={key}>
                    {value}
                  </option>))}
              </Select>

              {(subject as ContactFormSubject) === ContactFormSubject.Other && (<FormControl pb={8} isInvalid={!!errors.subject} variant="floating" maxW="628px">
                  <Input id="subject" placeholder=" " {...register('subject', { required: t('subject-cannot-be-empty') })} autoFocus/>
                  <FormLabel htmlFor="subject">{t('subject')}</FormLabel>
                  <FormErrorMessage>{errors?.subject?.message}</FormErrorMessage>
                </FormControl>)}

              <FormControl pb={8} isInvalid={!!errors.message} variant="floating" maxW="628px">
                <Textarea id="message" placeholder=" " {...register('message', { required: t('message-cannot-be-empty') })} borderColor="greyCCC" _focus={{ borderColor: 'grey999' }} borderRadius="2px" _placeholder={{ color: 'primary.black' }} resize="none" h="186px"/>
                <FormLabel htmlFor="message">{t('message')}</FormLabel>
                <FormErrorMessage>{errors?.message?.message}</FormErrorMessage>
              </FormControl>

              <Button type="submit" mt={6} isDisabled={!isValid} isLoading={isSubmitting} w="136px">
                {t('submit')}
              </Button>
            </form>)}
        </Container>
      </Flex>
    </Layout>);
};
export default ContactFormPage;

    async function __Next_Translate__getStaticProps__1927b45dbd6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/contact-form/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1927b45dbd6__ as getStaticProps }
  